function get_age(date){
	date = date.slice(0, 10)
	var today = new Date();
	var birthday = new Date(date);
	var year = 0;
	if (today.getMonth() < birthday.getMonth()) {
		year = 1;
	} else if ((today.getMonth() == birthday.getMonth()) && today.getDate() < birthday.getDate()) {
		year = 1;
	}
	var age = today.getFullYear() - birthday.getFullYear() - year;

	if(age < 0){
		age = 0;
	}

	return age
}

export default get_age