<template>
	<div>
		<LoadingScreen v-if="loading_screen" />
		<ContentHeader title="Resepsionis" />
		<div class="content-header">
			<div class="container-fluid">
				<div class="card">
				<div class="card-header">
					<h3 class="card-title"><div>Tambah Kunjungan Pasien</div></h3>
					<div class="card-tools">
						<router-link to="/tambah-data-pasien" class="btn btn-sm btn-success">Tambah pasien baru</router-link>
					</div>
				</div>
				<div class="card-body" style="display: block;">
					<form @submit.prevent="prosesKunjungan">
						<div class="row">
							<div class="col-lg-2"></div>
							<div class="col-lg-8">
								<div class="form-group">
									<label for="nomor">Nomor Antrian </label>
									<input type="text" class="form-control"  id="nomor" disabled="" name="nomor" placeholder="" v-model="kunjunganBaru.nomor">
								</div>
								<div class="w-100 mb-3">
									<label for="pasien">Pasien <span class="text-danger" >*</span></label>
									<el-select id="pasien" v-model="kunjunganBaru.pasien_id" filterable remote reserve-keyword placeholder="Masukkan Nama Pasien" class="w-100" :remote-method="getPasienRemote" :loading="loading_pasien">
										<el-option v-for="(row, index) in pasien" :key="index" :label="customSelectPasien(row)" :value="row.id" />
									</el-select>
								</div>
								<div class="w-100 mb-3">
									<label for="dokter">Dokter<span class="text-danger" >*</span></label>
									<el-select id="dokter" v-model="kunjunganBaru.dokter_id" filterable placeholder="Pilih Dokter" class="w-100" :loading="loading_dokter">
										<el-option v-for="(row, index) in dataDokter" :key="index" :label="row.nama_lengkap" :value="row.id" />
									</el-select>
								</div>
								<div class="form-group">
									<label class="typo__label">Poli<span class="text-danger" >*</span></label><br>
									<el-select v-model="kunjunganBaru.poli_id" placeholder="Pilih Poli" filterable class="w-100" :disabled="cabang.tipe.toLowerCase() == 'kecantikan'">
										<el-option v-for="(row, index) in dataPoli" :key="index" :value="row.poli_id" :label="row.nama"></el-option>
									</el-select>
									<p class="text-sm text-danger" v-if="cek_data_poli">Data Poli Masih Kosong</p>
								</div>
								<div class="form-group">
									<label class="typo__label">Layanan<span class="text-danger" >*</span></label><br>
									<el-select v-model="kunjunganBaru.layanan_id" placeholder="Pilih Layanan" filterable class="w-100" :disabled="cabang.tipe.toLowerCase() == 'kecantikan'">
										<el-option v-for="(row, index) in dataLayanan" :key="index" :value="row.id" :label="row.nama"></el-option>
									</el-select>
									<p class="text-sm text-danger" v-if="cek_data_poli">Data Poli Masih Kosong</p>
								</div>
								<button type="submit" class="btn btn-primary d-flex align-items-center mt-4">
									<div>Submit</div>	
									<div class="spinner-border spinner-border-sm ml-2" v-if="loading" role="status">
									<span class="sr-only">Loading...</span>
									</div>
								</button>	
							</div>
							<div class="col-lg-2"></div>
						</div>
					</form>
				</div>
				<!-- /.card-body -->
				<div class="card-footer" style="display: block;">
					
				</div>
				<!-- /.card-footer-->
				</div>


				<div class="card">
				<div class="card-header">
					<h3 class="card-title"><div>Kunjungan Hari Ini</div></h3>

					<div class="card-tools">
					<button type="button" class="btn btn-tool" data-card-widget="collapse" title="Collapse">
						<i class="fas fa-minus"></i>
					</button>
					</div>
				</div>
				<div class="card-body" style="display: block;">
					<table class="table table-bordered table-hover">
					<thead>
						<tr>
						<th scope="col" class="text-sm text-center">No Antrian</th>
						<th scope="col" class="text-sm">Poli</th>
						<th scope="col" class="text-sm">Layanan</th>
						<th scope="col" class="text-sm">NAMA</th>
						<th scope="col" class="text-sm">DOKTER</th>
						<th scope="col" class="text-sm">NOMOR TELEPON</th>
						<th scope="col" class="text-sm text-center">STATUS</th>
						<th scope="col" class="text-sm">WAKTU KUNJUNGAN</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="row in kunjungan" :key="row.id">
							<td class="text-sm text-center" scope="row">{{ row.nomor }}</td>
							<td class="text-sm">{{ row.poli.nama }}</td>
							<td class="text-sm">{{ row.layanan.nama }}</td>
							<td class="text-sm"><b>{{ row.pasien.nomor_rekam_medis }}</b> - {{ row.nama_pasien }}</td>
							<td class="text-sm">{{ row.dokter.nama_lengkap}}</td>
							<td class="text-sm">{{ row.nomor_telepon }}</td>
							<td class="text-sm text-center">{{ row.status }}</td>
							<td class="text-sm text-center">{{ row.waktu_kunjungan }}</td>
						</tr>
					</tbody>
					</table>
					<p class="text-center mt-2">{{cekDataKunjungan}}</p>
				</div>
				<!-- /.card-body -->
				<div class="card-footer" style="display: block;">
					
				</div>
				<!-- /.card-footer-->
				</div>

			</div>
		</div>
	</div>
</template>

<script setup>
	import ContentHeader from '@/components/ContentHeader'
	import LoadingScreen from '@/components/LoadingScreen'

	import { ref, computed, reactive, onMounted } from 'vue'
	import axios from 'axios'
	import store from '@/store'
	import Swal from 'sweetalert2'
	import umur from '@/umur.js'

	const user = computed(() => store.getters['auth/user'])
	const cabang = computed(() => store.getters['auth/cabang'])
	

	// PASIEN
	const pasien = ref([])
	const loading_pasien = ref(false)
	const cek_data_pasien = ref(false)

	const getPasienRemote = async (val) => {
		loading_pasien.value = true
		let response = await axios.get(`api/pasien/getRemote/${user.value.cabang_id}/${val}/${cabang.value.tipe}`)
		
		if (response.data == 'kosong') {
			loading_pasien.value = false
			cek_data_pasien.value = true
			pasien.value = []
		} else {
			loading_pasien.value = false
			cek_data_pasien.value = false
			pasien.value = response.data
		}
	}

	const customSelectPasien = ({ nomor_rekam_medis, nama, alamat_lengkap, tanggal_lahir }) => {
		tanggal_lahir = tanggal_lahir ? `${umur(tanggal_lahir)} Tahun - ` : ''
		return `${nomor_rekam_medis} - ${nama} - ${tanggal_lahir} ${alamat_lengkap}`
	}
	// END PASIEN

	// DOKTER
	const dataDokter = ref([])
	const loading_dokter = ref(true)
	const cek_data_dokter = ref(false)

	const getDokter = async () => {
		let response = await axios.get(`api/karyawan/getAllWhereJabatan/${user.value.cabang_id}/dokter`)

		if (response.data == 'kosong') {
			loading_dokter.value = false
			cek_data_dokter.value = true
			dataDokter.value = []
		} else {
			loading_dokter.value = false
			cek_data_dokter.value = false
			dataDokter.value = response.data
		}
	}
	// END DOKTER


	// POLI
	const dataPoli = ref([])
	const loading_poli = ref(true)
	const cek_data_poli = ref(false)

	const getPoli = async () => {
		await axios.get(`api/poli/getWhereAktifRelationPoli/${user.value.cabang_id}`)
		.then((response) => {
			if (response.data == 'kosong') {
				loading_poli.value = false
				cek_data_poli.value = true
				dataPoli.value = []
			} else {
				loading_poli.value = false
				cek_data_poli.value = false
				dataPoli.value = response.data
			}
			
			getLayanan()

		})

	}

	// END POLI

	const cekCabangLogin = (poli, layanan) => {
		if(cabang.value.tipe == 'kecantikan') {
			poli.map((val) => {
				if(val.nama.toLowerCase() == 'kecantikan') {
					kunjunganBaru.poli_id = val.poli_id
				}
			})

			layanan.map((val) => {
				if(val.nama.toLowerCase() == 'umum/mandiri') {
					kunjunganBaru.layanan_id = val.id
				}
			})

		}
	}

	// LAYANAN
	const dataLayanan = ref([])
	const loading_layanan = ref(true)
	const cek_data_layanan = ref(false)

	const getLayanan = async () => {
		await axios.get(`api/layanan/getWhereAktif`)
		.then((response) => {
			if (response.data == 'kosong') {
				loading_layanan.value = false
				cek_data_layanan.value = true
				dataLayanan.value = []
			} else {
				loading_layanan.value = false
				cek_data_layanan.value = false
				dataLayanan.value = response.data
			}

			cekCabangLogin(dataPoli.value, dataLayanan.value)
		})

	}
	// END LAYANAN

	// KUNJUNGAN
	const { kunjungan, getKunjungan, prosesKunjungan, kunjunganBaru, cekDataKunjungan, loading, loading_screen } = useKunjungan(user, cekCabangLogin, dataPoli)	    	
	// END KUNJUNGAN

	onMounted(() => {
		getDokter()
		getKunjungan()
		getPoli()
	})

	function useKunjungan (user, cekCabangLogin, dataPoli) {
		const kunjungan = ref()
		const kunjunganBaru = reactive({
			'cabang_id' 	: user.value.cabang_id,
			'nomor'			: null,
			'pasien_id' 	: null,
			'poli_id'		: null,
			'layanan_id'	: null,
			'status' 		: 'dalam antrian',
			'jenis'			: 'resepsionis'
		})
		const cekDataKunjungan = ref()
		const loading = ref(false)
		const loading_screen = ref(true)

		const tahun = ref()
		const bulan = ref()
		const hari = ref()

		const date = new Date()

		const getKunjungan = async () => {

			tahun.value = date.getFullYear()
			tahun.value = tahun.value.toString().slice(2, 4)
			bulan.value = date.getMonth() + 1
			bulan.value = (bulan.value > 9) ? bulan.value : '0' + bulan.value
			hari.value = date.getDate()
			hari.value = (hari.value > 9) ? hari.value : '0' + hari.value

			let response = await axios.get('api/kunjungan/getByDay/' + user.value.cabang_id)

			if (response.data == 'kosong') {
			cekDataKunjungan.value = 'Belum Ada Kunjungan'
			kunjungan.value = null
			kunjunganBaru.nomor = `${tahun.value}${bulan.value}${hari.value}001`
			loading_screen.value = false
			} else {
			cekDataKunjungan.value = ''
			kunjungan.value = response.data
			let no_urut = response.data[0].nomor
			no_urut = no_urut.slice(6, 9)
			no_urut = parseInt(no_urut) + 1
			no_urut = (no_urut > 9) ? (no_urut > 99) ? no_urut : '0' + no_urut : '00' + no_urut

			kunjunganBaru.nomor = `${tahun.value}${bulan.value}${hari.value}${no_urut}`
			loading_screen.value = false
			}	

		}

		// Proses Kunjungan
		const resetKunjunganBaru = () => {
			kunjunganBaru.nomor 		= null
			kunjunganBaru.pasien_id 	= null
			kunjunganBaru.dokter_id 	= null
			kunjunganBaru.poli_id		= null
			kunjunganBaru.layanan_id	= null
		}

		const prosesKunjungan = () => {
			if (loading.value == false) {
				if(kunjunganBaru.pasien_id && kunjunganBaru.dokter_id && kunjunganBaru.poli_id && kunjunganBaru.layanan_id){
					loading.value = true
					axios.post('api/kunjungan/create', kunjunganBaru)
					.then((response) => {
						if (response.data == 'sudah ada') {
							Swal.fire({
								title: 'Gagal!',
								text: 'Pasien Dalam Antrian Poli',
								icon: 'error',
								showConfirmButton: false,
								timer: 1500
							})	
							loading.value = false
						} else {
							Swal.fire({
								title: 'Berhasil!',
								text: 'Berhasil Menambahkan Kunjungan',
								icon: 'success',
								showConfirmButton: false,
								timer: 1500
							})	
							loading.value = false
							resetKunjunganBaru()
							getKunjungan()	
							cekCabangLogin(dataPoli.value, dataLayanan.value)
						}
					})
					.catch((error) => {
						Swal.fire({
						title: 'Gagal!',
						text: 'Gagal menambahkan data',
						icon: 'error',
						showConfirmButton: false,
						timer: 1500
						})	
						loading.value = false
						console.log(error)
					})
				} else {
					Swal.fire({
						title: 'Gagal!',
						text: 'Silahkan Isi Semua Form',
						icon: 'error',
						showConfirmButton: false,
						timer: 1500
					})	
				}
			}
		}
		// End Proses Kunjungan

		return { kunjungan, getKunjungan, prosesKunjungan, kunjunganBaru, cekDataKunjungan, loading, loading_screen }
	}
</script>